import * as React from "react"
import { Link, graphql } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Helmut from "../components/helmut"

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Helmut title="Powerlifting Stream" />
      <p>Powerlifting Streamed Live</p>
      <br />
      <Link to="/groupphoto">
      <StaticImage
        src="../images/IMG_0030.JPG"
        alt="Photo of PowerWOD team."
        placeholder="blurred"
        style={{
          marginBottom: "1em",
        }}
      />
      </Link>

      <br />


      <Link to="/events">Past Event Streams</Link>

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
